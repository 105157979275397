import request from '@/utils/request'

// 付费陈列列表
export function getList(data) {
  return request({
    url: '/promoteAdmin/display/index',
    method: 'post',
    data,
  })
}
// 创建付费陈列
export function addPayList(data) {
  return request({
    url: '/promoteAdmin/display/create',
    method: 'post',
    data,
  })
}

// 陈列详情
export function getPayListDetail(data) {
  return request({
    url: '/promoteAdmin/display/view',
    method: 'post',
    data,
  })
}

// 修改
export function editPayList(data) {
  return request({
    url: '/promoteAdmin/display/update',
    method: 'post',
    data,
  })
}

// 修改活动指定字段(禁、启用)
export function toggleStatus(data) {
  return request({
    url: '/promoteAdmin/display/update-field',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {陈列类型} data
 */
// 获取
/**
pageNo:
pageSize:
type:类型 1快速品牌 2商品备注 3陈列商品类型 4陈列陈列商品包装
 */
export function styleList(data) {
  return request({
    url: '/baseAdmin/common/admin-type',
    method: 'post',
    data,
  })
}

// 通用类型编辑/创建
export function editStyle(data) {
  return request({
    url: '/baseAdmin/common/admin-modify',
    method: 'post',
    data,
  })
}

// 通用类型删除
export function deleteStyle(data) {
  return request({
    url: '/baseAdmin/common/admin-delete',
    method: 'post',
    data,
  })
}

// 经销商照片类型
export function phoneList(data) {
  return request({
    url: '/baseAdmin/common/dealer-photo-type-option',
    method: 'post',
    data,
  })
}

// 已关联客户
export function getClientList(data) {
  return request({
    url: '/promoteAdmin/display/cust-list',
    method: 'post',
    data,
  })
}
