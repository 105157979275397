var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "df" }, [
        _c(
          "div",
          { staticClass: "cen" },
          [
            _c(
              "el-checkbox",
              {
                staticStyle: { "margin-right": "15px" },
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [_vm._v(" 指定陈列终端 ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-table",
              { attrs: { stripe: "", data: _vm.list, border: "" } },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "num",
                    label: "指定终端",
                    width: "150px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "",
                    label: "操作",
                    width: "110px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleEdit($index, row)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("link-client", {
        ref: "linkClient",
        on: { "set-client-num": _vm.setClientNum },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }