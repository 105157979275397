var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "110px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称:", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      placeholder: "请输入活动名称",
                      disabled: !_vm.isEdit,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "报名时间:", prop: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      disabled: !_vm.isEdit,
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "df" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "陈列开始日期", prop: "add_start_time" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "175px" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                          disabled: !_vm.isEdit,
                        },
                        model: {
                          value: _vm.form.add_start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "add_start_time", $$v)
                          },
                          expression: "form.add_start_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "陈列天数:", prop: "show_day" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65px" },
                        attrs: { disabled: !_vm.isEdit },
                        model: {
                          value: _vm.form.show_day,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "show_day", $$v)
                          },
                          expression: "form.show_day",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "df" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "限定业务员:", prop: "" } },
                    [
                      _c("span", { staticStyle: { "margin-right": "25px" } }, [
                        _vm._v(" " + _vm._s(_vm.form.as_user_ids.length) + " "),
                      ]),
                      _vm.isEdit
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSetStaff },
                            },
                            [_vm._v(" 设置 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "join_more" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            "true-label": 1,
                            "false-label": 0,
                            disabled: _vm.checkbox,
                          },
                          model: {
                            value: _vm.form.join_more,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "join_more", $$v)
                            },
                            expression: "form.join_more",
                          },
                        },
                        [_vm._v(" 活动可重复参加 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "限定门店家数:", prop: "cust_limit" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80px" },
                    model: {
                      value: _vm.form.cust_limit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cust_limit", $$v)
                      },
                      expression: "form.cust_limit",
                    },
                  }),
                  _vm._v(" 家 "),
                  _c(
                    "span",
                    { staticStyle: { color: "red", "margin-left": "10px" } },
                    [
                      _vm._v(
                        " 不填写数量时无限制，填入数量达到家数后不能再邀请新户参加此活动 "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动说明:", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { type: "textarea", disabled: !_vm.isEdit },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("set-component", {
            ref: "setComponent",
            attrs: { "is-create": true, chenlie: true },
            on: { "set-userid": _vm.setUserId },
          }),
          _vm.show
            ? _c("table1", {
                ref: "table1",
                staticStyle: { "margin-bottom": "15px" },
                attrs: { data: _vm.form.model_goods, "is-edit": _vm.isEdit },
              })
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { overflow: "hidden" } },
            [
              _vm._l(_vm.list2, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { position: "relative" } },
                  [
                    _vm.isEdit
                      ? _c("div", { staticClass: "icons" }, [
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-document-copy",
                              on: {
                                click: function ($event) {
                                  return _vm.copyPlan2(index)
                                },
                              },
                            },
                            [_vm._v("复制")]
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "el-icon-close",
                              on: {
                                click: function ($event) {
                                  return _vm.deletePlan2(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "el-card",
                      { attrs: { shadow: "hover" } },
                      [
                        _c("div", { ref: "tt", refInFor: true }, [
                          _vm._v("第 " + _vm._s(index + 1) + " 期"),
                        ]),
                        _c(
                          "el-table",
                          {
                            staticStyle: { "margin-top": "15px" },
                            attrs: { stripe: "", data: item.goods_info },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                width: "220px",
                                prop: "goods_name",
                                label: "兑付商品",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("goods-search", {
                                          ref: "goodsSearch",
                                          refInFor: true,
                                          attrs: {
                                            "f-key": row.goods_name,
                                            search: _vm.isEdit,
                                            disab: !_vm.isEdit,
                                            "is-old": "",
                                          },
                                          on: {
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods2(
                                                $event,
                                                row,
                                                index
                                              )
                                            },
                                            "add-rows": function ($event) {
                                              return _vm.addRows2($event, index)
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "unit_id",
                                label: "单位",
                                width: "110px",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100px" },
                                            attrs: {
                                              placeholder: "单位",
                                              disabled: !_vm.isEdit,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitChange2(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_id", $$v)
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(
                                            row.arr_unit,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.id,
                                                  label: i.unit_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_price",
                                label: "价格",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.goods_price || 0) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "number",
                                label: "数量",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            placeholder: "数量",
                                            min: 0,
                                            disabled: !_vm.isEdit,
                                          },
                                          model: {
                                            value: row.number,
                                            callback: function ($$v) {
                                              _vm.$set(row, "number", $$v)
                                            },
                                            expression: "row.number",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "金额",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              Number(
                                                Number(row.number) *
                                                  row.goods_price
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "remark",
                                label: "备注",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "备注",
                                            disabled: !_vm.isEdit,
                                          },
                                          model: {
                                            value: row.remark,
                                            callback: function ($$v) {
                                              _vm.$set(row, "remark", $$v)
                                            },
                                            expression: "row.remark",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm.isEdit
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "操作",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var $index = ref.$index
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleCopyRow2(
                                                      $index,
                                                      row,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 复制 ")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleDeleteRow2(
                                                      $index,
                                                      row,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 删除 ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              margin: "10px 0",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _vm._v(" 兑付现金: "),
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { disabled: !_vm.isEdit },
                                  model: {
                                    value: item.total_amount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "total_amount",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "item.total_amount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm._v(" 备注: "),
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { disabled: !_vm.isEdit },
                                  model: {
                                    value: item.remark,
                                    callback: function ($$v) {
                                      _vm.$set(item, "remark", $$v)
                                    },
                                    expression: "item.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _vm._v(" 兑付日期: "),
                            _c("el-date-picker", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                format: "yyyy 年 MM 月 dd 日",
                                "value-format": "yyyy-MM-dd",
                                disabled: !_vm.isEdit,
                              },
                              model: {
                                value: item.time_text,
                                callback: function ($$v) {
                                  _vm.$set(item, "time_text", $$v)
                                },
                                expression: "item.time_text",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    margin: "10px 0",
                  },
                },
                [
                  _vm.isEdit
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: { click: _vm.handleBatchAdd },
                        },
                        [_vm._v(" 一键复制多期兑付 ")]
                      )
                    : _vm._e(),
                  _vm.isEdit
                    ? _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.add2 } },
                        [_vm._v(" 添加新兑付 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm.show
            ? _c("client", {
                ref: "client",
                attrs: {
                  nums: _vm.form.as_cust_ids,
                  idd: _vm.form.id,
                  "is-edit": _vm.isEdit,
                },
                on: { "set-client": _vm.setClient },
              })
            : _vm._e(),
          _vm.show
            ? _c("phone", {
                ref: "phone",
                attrs: { data: _vm.form.cash_photo, "is-edit": _vm.isEdit },
              })
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-top": "15px", "margin-left": "130px" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0, disabled: !_vm.isEdit },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v(" 启用 ")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 1, disabled: !_vm.isEdit },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v(" 停用 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.copy && _vm.isEdit
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleEdit } },
                [_vm._v(" 修改 ")]
              )
            : _vm._e(),
          _vm.copy && _vm.isEdit
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleCopy } },
                [_vm._v(" 复制 ")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
      _c("batch-add", { ref: "batchAdd", on: { "batch-add": _vm.batchAdd } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }