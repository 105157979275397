var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "df" }, [
        _c("div", { staticClass: "cen" }, [_vm._v("陈列拍照")]),
        _c(
          "div",
          [
            _c(
              "el-table",
              { attrs: { stripe: "", data: _vm.list, border: "" } },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      align: "center",
                      prop: "photo_type_id",
                      label: "照片类型",
                      width: "150px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "110px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "照片类型",
                                },
                                on: { change: _vm.phoneChange },
                                model: {
                                  value: row.photo_type_id,
                                  callback: function ($$v) {
                                    _vm.$set(row, "photo_type_id", $$v)
                                  },
                                  expression: "row.photo_type_id",
                                },
                              },
                              _vm._l(_vm.phoneSelect, function (i, idx) {
                                return _c("el-option", {
                                  key: idx,
                                  attrs: { value: i.id, label: i.type_name },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _vm._v(" 照片类型 "),
                      _c("i", {
                        staticClass: "el-icon-edit-outline",
                        on: { click: _vm.handleShowDialog },
                      }),
                    ]),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "num",
                    label: "数量",
                    width: "150px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-input", {
                            attrs: {
                              placeholder: "数量",
                              min: 0,
                              type: "number",
                            },
                            model: {
                              value: row.num,
                              callback: function ($$v) {
                                _vm.$set(row, "num", $$v)
                              },
                              expression: "row.num",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "info",
                    label: "备注",
                    width: "180px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-input", {
                            attrs: { placeholder: "备注" },
                            model: {
                              value: row.info,
                              callback: function ($$v) {
                                _vm.$set(row, "info", $$v)
                              },
                              expression: "row.info",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "",
                    label: "操作",
                    width: "110px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleDelete($index, row)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("PhoneTypeEdit", {
        ref: "phoneTypeEdit",
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }