<template>
  <div>
    <div class="df">
      <div class="cen">陈列拍照</div>
      <div>
        <el-table stripe :data="list" border>
          <el-table-column
            align="center"
            prop="photo_type_id"
            label="照片类型"
            width="150px"
          >
            <template slot="header">
              照片类型
              <i class="el-icon-edit-outline" @click="handleShowDialog"></i>
            </template>
            <template #default="{ row }">
              <el-select
                v-model="row.photo_type_id"
                clearable
                style="width: 110px"
                placeholder="照片类型"
                :disabled="!isEdit"
                @change="phoneChange"
              >
                <el-option
                  v-for="(i, idx) in phoneSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.type_name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="num" label="数量" width="150px">
            <template #default="{ row }">
              <el-input
                v-model="row.num"
                placeholder="数量"
                type="number"
                :min="0"
                :disabled="!isEdit"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="info"
            label="备注"
            width="180px"
          >
            <template #default="{ row }">
              <el-input
                v-model="row.info"
                placeholder="备注"
                :disabled="!isEdit"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            v-if="isEdit"
            align="center"
            prop=""
            label="操作"
            width="110px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleAddRow($index, row)"
              >
                添加
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDelete($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <PhoneTypeEdit ref="phoneTypeEdit" @refresh="refresh"></PhoneTypeEdit>
  </div>
</template>
<script>
  import PhoneTypeEdit from '../add/phoneTypeEdit.vue'

  import { postAction } from '@/api/Employee'

  export default {
    name: 'Phone',
    components: { PhoneTypeEdit },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      isEdit: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        phoneSelect: [],
        list: [
          {
            photo_type_id: '',
            title: '',
            info: '',
            num: '',
            is_close: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      data(v) {
        this.list = v
      },
    },
    created() {
      this.initSelect()
      this.list = JSON.parse(JSON.stringify(this.data))
    },
    mounted() {},
    methods: {
      initSelect() {
        postAction('/baseAdmin/common/dealer-photo-type-option', {
          type: 2,
        }).then((res) => {
          this.phoneSelect = res.data
        })
      },
      handleDelete(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
      phoneChange(val) {
        if (val && this.list[this.list.length - 1].photo_type_id !== '') {
          this.list.push({
            photo_type_id: '',
            title: '',
            info: '',
            num: '',
            is_close: '',
          })
        }
      },
      refresh() {
        this.initSelect()
      },
      handleShowDialog() {
        this.$refs.phoneTypeEdit.showDialog = true
      },
      handleAddRow() {
        this.list.push({
          photo_type_id: '',
          title: '',
          info: '',
          num: '',
          is_close: '',
        })
      },
      save() {
        let data = []
        this.list.forEach((item) => {
          if (item.photo_type_id !== '') {
            data.push(item)
          }
        })
        data.forEach((item) => {
          if (item.photo_type_id !== '') {
            item.title = this.phoneSelect.filter(
              (i) => i.id == item.photo_type_id
            )[0].type_name
          }
        })
        console.log('phone:', data)
        return JSON.stringify(data)
      },
      clearForm() {
        this.list = [
          {
            photo_type_id: '',
            title: '',
            info: '',
            num: '',
            is_close: '0启用，1禁用',
          },
        ]
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
  }
  .cen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 125px;
    padding-right: 15px;
    line-height: 35px;
    text-align: right;
  }
</style>
