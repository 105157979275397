<template>
  <el-dialog
    title="一键多期兑付"
    :visible.sync="showDialog"
    width="500px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="115px"
        label-suffix=":"
      >
        <el-form-item label="第一期兑付时间" prop="">
          <el-input v-model="form.time" disabled></el-input>
        </el-form-item>
        <el-form-item label="兑付总期数" prop="">
          <el-input v-model.number="form.qi" :max="12" type="number"></el-input>
        </el-form-item>
        <div class="df">
          <div class="df1">
            <el-radio
              v-model="form.checked"
              label="1"
              style="width: 115px; padding-right: 15px"
            >
              间隔天数:
            </el-radio>
          </div>
          <div class="df2">
            <el-input
              v-model.number="form.day1"
              :max="31"
              :min="1"
              type="number"
            >
              <template slot="append">天</template>
            </el-input>
          </div>
        </div>
        <div class="df" style="margin-top: 15px">
          <div class="df1">
            <el-radio
              v-model="form.checked"
              style="width: 115px; padding-right: 15px"
              label="2"
            >
              每月固定:
            </el-radio>
          </div>
          <div class="df2">
            <el-input
              v-model.number="form.day2"
              :max="31"
              :min="1"
              type="number"
            >
              <template slot="append">日</template>
            </el-input>
          </div>
        </div>
      </el-form>
      <div style="margin-top: 15px; text-align: right">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'BatchAdd',
    components: {},
    data() {
      return {
        showDialog: false,
        form: {
          time: '',
          checked: '1',
          qi: '',
          day1: '',
          day2: '',
        },
      }
    },
    computed: {},
    watch: {
      'form.time'(val) {
        if (val) {
          let a = val.split('-')
          this.form.day2 = Number(a[a.length - 1].replace(/^0/g, ''))
        }
      },
      showDialog(val) {
        if (!val) {
          this.form = {
            time: '',
            checked: '1',
            qi: '',
            day1: '',
            day2: '',
          }
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      handleSubmit() {
        if (this.form.qi > 12) {
          this.$message.error('兑付总期数最多12期')
        } else {
          if (!this.form.qi) {
            this.$message.error('请输入期数')
          } else {
            if (this.form.checked == 1 && !this.form.day1) {
              this.$message.error('请输入间隔天数')
            } else if (this.form.checked == 2 && !this.form.day2) {
              this.$message.error('请输入每月固定日')
            } else {
              this.$emit('batch-add', this.form)
              this.close()
            }
          }
        }
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    align-items: center;
  }
  .df1 {
    width: 115px;
    padding-right: 15px;
    text-align: right;
  }
  .df2 {
    flex-grow: 1;
  }
</style>
