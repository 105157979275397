var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "编辑拍照类型",
        visible: _vm.showDialog,
        width: "600px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _vm._l(_vm.tagList, function (tag, idx) {
            return _c(
              "el-tag",
              {
                key: idx,
                attrs: { closable: "", "disable-transitions": false },
                on: {
                  close: function ($event) {
                    return _vm.handleClose(tag)
                  },
                },
              },
              [_vm._v(" " + _vm._s(tag.name) + " ")]
            )
          }),
          _vm.inputVisible
            ? _c("el-input", {
                ref: "saveTagInput",
                staticClass: "input-new-tag",
                attrs: { size: "small" },
                on: { blur: _vm.handleInputConfirm },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleInputConfirm.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              })
            : _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { size: "small" },
                  on: { click: _vm.showInput },
                },
                [_vm._v(" 新建 ")]
              ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }