<template>
  <div style="overflow: hidden">
    <div v-for="(item, index) in list" :key="index" style="position: relative">
      <div class="icons">
        <i class="el-icon-document-copy" @click="copyPlan(index)">复制</i>
        <i class="el-icon-close" @click="deletePlan(index)">删除</i>
      </div>
      <el-card shadow="hover">
        <div ref="tt">第 {{ index + 1 }} 期</div>
        <el-table stripe :data="item.goods_info" style="margin-top: 15px">
          <el-table-column
            width="220px"
            prop="goods_name"
            label="兑付商品"
            align="center"
          >
            <template #default="{ row }">
              <goods-search
                ref="goodsSearch"
                :f-key="row.goods_name"
                is-old
                @select-goods-all="selectGoods($event, row, index)"
                @add-rows="addRows($event, index)"
              ></goods-search>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_id"
            label="单位"
            width="110px"
            align="center"
          >
            <template #default="{ row }">
              <el-select
                v-model="row.unit_id"
                style="width: 100px"
                placeholder="单位"
                @change="unitChange($event, row)"
              >
                <el-option
                  v-for="(i, idx) in row.arr_unit"
                  :key="idx"
                  :value="i.id"
                  :label="i.unit_name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="goods_price" label="价格" align="center">
            <template #default="{ row }">
              {{ row.goods_price || 0 }}
            </template>
          </el-table-column>
          <el-table-column prop="number" label="数量" align="center">
            <template #default="{ row }">
              <el-input
                v-model="row.number"
                type="number"
                :min="0"
                placeholder="数量"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="" label="金额" align="center">
            <template #default="{ row }">
              {{ Number(row.number * row.goods_price).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template #default="{ row }">
              <el-input v-model="row.remark" placeholder="备注"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleCopyRow($index, row, index)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow($index, row, index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="display: flex; justify-content: space-between; margin: 10px 0"
        >
          <div>
            兑付现金:
            <el-input
              v-model.number="item.total_amount"
              style="width: 200px"
            ></el-input>
          </div>
          <div>
            备注:
            <el-input v-model="item.remark" style="width: 200px"></el-input>
          </div>
        </div>
        <div>
          兑付日期:
          <el-date-picker
            v-model="item.time"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 200px"
          ></el-date-picker>
        </div>
      </el-card>
    </div>
    <div style="display: flex; justify-content: space-between; margin: 10px 0">
      <!--  一键多期兑付功能22 -->
      <el-button type="warning" @click="handleBatchAdd">
        一键复制多期兑付
      </el-button>
      <el-button type="primary" @click="add">添加新兑付</el-button>
    </div>
    <batch-add ref="batchAdd" @batch-add="batchAdd"></batch-add>
  </div>
</template>
<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import BatchAdd from '@/views/project/movablepin/payList/createPayList/components/check/batchAdd.vue'
  import { formatTimeStamp } from '@/utils/Time'
  export default {
    name: 'Table2',
    components: { GoodsSearch, BatchAdd },
    data() {
      return {
        list: [
          {
            title: '',
            time: '',
            total_amount: '',
            remark: '',
            goods_info: [
              {
                arr_unit: [],
                goods_name: '',
                goods_id: '',
                goods_price: '0',
                unit_id: '',
                number: '0',
                remark: '',
              },
            ],
          },
        ],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      selectGoods(val, row, index) {
        console.log(row)
        row.goods_name = val.goods_name
        row.goods_id = val.goods_id
        row.arr_unit = val.arr_unit
        row.unit_id = val.unit_id
        row.goods_price = val.goods_price
        row.remark = val.info
        row.number = val.quantity
        let p = row.arr_unit.filter((u) => u.id == row.unit_id)[0]
        if (p) row.goods_price = p.old_sell_price
        if (
          this.list[index].goods_info[this.list[index].goods_info.length - 1]
            .goods_id !== ''
        ) {
          this.addRow(index)
        }
      },
      addRow(index) {
        this.list[index].goods_info.push({
          goods_name: '',
          goods_id: '',
          unit_id: '',
          number: '0',
          remark: '',
          goods_price: '0',
          arr_unit: [],
        })
      },

      addRows(val, index) {
        val.forEach((item) => {
          this.list[index].goods_info.push({
            goods_name: item.goods_name,
            goods_id: item.goods_id,
            number: item.quantity,
            goods_price: item.goods_price,
            unit_id: item.unit_id,
            remark: item.info,
            arr_unit: item.arr_unit,
          })
        })
        this.list[index].goods_info.forEach((i, idx) => {
          if (i.goods_id == '' || i.goods_id == -1) {
            this.list[index].goods_info.splice(idx, 1)
          }
        })
        this.addRow(index)
      },
      // 表格操作
      handleCopyRow(index, row, idx) {
        this.list[idx].goods_info.splice(
          index,
          0,
          JSON.parse(JSON.stringify(row))
        )
      },
      handleDeleteRow(index, row, idx) {
        if (row.goods_name == '') {
          this.$message.error('不能删除此行')
          return false
        }
        if (this.list[idx].goods_info.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list[idx].goods_info.splice(index, 1)
        }
      },
      add() {
        this.list.push({
          title: '',
          time: '',
          total_amount: '',
          remark: '',
          goods_info: [
            {
              arr_unit: [],
              goods_name: '',
              goods_id: '',
              goods_price: '0',
              unit_id: '',
              number: '0',
              remark: '',
            },
          ],
        })
      },
      copyPlan(index) {
        this.list.splice(index, 0, JSON.parse(JSON.stringify(this.list[index])))
      },
      deletePlan(index) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一项')
        } else {
          this.list.splice(index, 1)
        }
      },
      unitChange(val, row) {
        console.log(val)
        row.goods_price = row.arr_unit.filter(
          (item) => item.id == val
        )[0].old_sell_price
      },
      clearForm() {
        this.list = {
          title: '',
          time: '',
          total_amount: '',
          remark: '',
          goods_info: [
            {
              arr_unit: [],
              goods_name: '',
              goods_id: '',
              goods_price: '0',
              unit_id: '',
              number: '0',
              remark: '',
            },
          ],
        }
      },
      save() {
        let data = JSON.parse(JSON.stringify(this.list))
        data.forEach((item, idx) => {
          console.log('aaa', this.$refs.tt[idx])
          item.goods_info.forEach((item2, index2) => {
            if (item2.goods_id == '') {
              item.goods_info.splice(index2, 1)
            }
          })
          item.title = this.$refs.tt[idx].innerHTML
        })
        console.log('table2:', data)
        return JSON.stringify(data)
      },
      // ? 一键兑付多期
      handleBatchAdd() {
        if (
          !this.list[0].time ||
          ((this.list[0].goods_info[0].goods_id == '' ||
            this.list[0].goods_info[0].goods_id == -1 ||
            this.list[0].goods_info[0].goods_id == undefined) &&
            !this.list[0].total_amount)
        ) {
          this.$message.error('请输入兑付内容及兑付日期')
        } else {
          this.$refs.batchAdd.form.time = this.list[0].time

          this.$refs.batchAdd.showDialog = true
        }

        // else {
        //   this.$refs.batchAdd.showDialog = true
        // }
      },
      batchAdd(val) {
        console.log('batch-add', val)
        // 时间戳
        let timeStamp = +new Date(val.time)
        // 一天的时间戳
        let dayStamp = 24 * 60 * 60 * 1000
        for (let i = 0; i < val.qi; i++) {
          if (val.checked == 1) {
            this.list.push({
              title: '',
              time: formatTimeStamp(
                timeStamp + (i + 1) * (val.day1 * dayStamp)
              ),
              total_amount: JSON.parse(
                JSON.stringify(this.list[0].total_amount)
              ),
              remark: JSON.parse(JSON.stringify(this.list[0].remark)),
              goods_info: JSON.parse(JSON.stringify(this.list[0].goods_info)),
            })
          } else if (val.checked == 2) {
            let nM = new Date(val.time).getMonth() + 1
            let month = new Date(timeStamp).setMonth(nM + i)
            let day = new Date(month).setDate(val.day2)
            let c = nM + i + 1 > 12 ? nM + i + 1 - 12 : nM + i + 1
            if (new Date(day).getMonth() + 1 != c) {
              console.log('这个月不对劲')
              // 不对劲的月份
              let m = new Date(day).getMonth() + 1
              let y = new Date(day).getFullYear()
              console.log('不对劲的月份', m - 1, y)
              day = new Date(y, m - 1 == 0 ? 1 : m - 1, 0)
              // let a = new Date(new Date(day).setMonth(m - 2))
              // let endDay = new Date(y, m - 1, 0).getDate()
              // console.log('endDay', endDay)
              // console.log('month', a.getMonth() + 1)
              // day = new Date(a).setDate(endDay)
              // console.log('day', new Date(day).getMonth() + 1)
            }
            this.list.push({
              title: '',
              time: formatTimeStamp(day),
              total_amount: JSON.parse(
                JSON.stringify(this.list[0].total_amount)
              ),
              remark: JSON.parse(JSON.stringify(this.list[0].remark)),
              goods_info: JSON.parse(JSON.stringify(this.list[0].goods_info)),
            })
          } else {
            this.$message.error('输入错误，请重新兑付')
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .icons {
    position: absolute;
    top: 10px;
    right: 10px;
    i {
      margin-right: 5px;
      cursor: pointer;
    }
  }
</style>
