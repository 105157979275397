<template>
  <div>
    <el-table v-loading="loading" stripe :data="model_goods">
      <el-table-column
        prop="goods_name"
        label="陈列商品"
        width="220px"
        align="center"
      >
        <template #default="{ row, $index }">
          <goods-search
            ref="goodsSearch"
            :f-key="row.goods_name"
            @select-goods-all="selectGoodsId($event, row)"
            @add-rows="addRows($event, $index)"
          ></goods-search>
        </template>
      </el-table-column>
      <el-table-column
        prop="display_type"
        label="陈列类型"
        width="150px"
        align="center"
      >
        <template slot="header">
          陈列类型
          <i class="el-icon-edit-outline" @click="editStyle"></i>
        </template>
        <template #default="{ row }">
          <el-select
            v-model="row.display_type"
            clearable
            style="width: 140px"
            placeholder="陈列类型"
          >
            <el-option
              v-for="(i, idx) in styleSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="数量" width="" align="center">
        <template #default="{ row }">
          <el-input
            v-model="row.number"
            type="number"
            :min="0"
            placeholder="数量"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="unit_id" label="单位" width="110px" align="center">
        <template slot="header">
          单位
          <i class="el-icon-edit-outline" @click="editUnit"></i>
        </template>
        <template #default="{ row }">
          <el-select
            v-model="row.unit_id"
            clearable
            style="width: 100px"
            placeholder="单位"
          >
            <el-option
              v-for="(i, idx) in unitSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="" align="center">
        <template #default="{ row }">
          <el-input v-model="row.remark" placeholder="备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="" align="center">
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCopyRow($index, row)"
          >
            复制
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleDeleteRow($index, row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :modal="false"
      :title="title"
      :visible.sync="showDialog"
      width="600px"
      top="15vh"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div class="orderTest-container">
        <el-tag
          v-for="(tag, idx) in tagList"
          :key="idx"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag.name }}
        </el-tag>
        <el-input
          v-if="inputVisible"
          ref="saveTagInput"
          v-model="inputValue"
          class="input-new-tag"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        ></el-input>
        <el-button
          v-else
          class="button-new-tag"
          size="small"
          @click="showInput"
        >
          新建
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { deleteRow } from '@/api/depotCheck'
  import { styleList, editStyle, deleteStyle } from '@/api/payList'
  export default {
    name: 'Table1',
    components: { GoodsSearch },
    data() {
      return {
        type: 3,
        tagList: [],
        inputVisible: false,
        inputValue: '',

        showDialog: false,
        title: '',
        styleSelect: [],
        unitSelect: [],
        model_goods: [
          {
            goods_name: '',
            goods_id: '',
            display_type: '',
            number: '',
            unit_id: '',
            remark: '',
          },
        ],
        loading: false,
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          // 获取类型
          this.getStyle()
        } else {
          this.getStyleList(3)
          this.getStyleList(4)
        }
      },
    },
    created() {
      this.getStyleList(3)
      this.getStyleList(4)
    },
    mounted() {},
    methods: {
      handleClose(tag) {
        // this.tagList.splice(this.tagList.indexOf(tag), 1)
        console.log(tag)
        deleteStyle({ id: tag.id }).then((res) => {
          this.getStyle()
          this.$message.success('删除成功')
        })
      },

      showInput() {
        this.inputVisible = true
        this.$nextTick((_) => {
          this.$refs.saveTagInput.$refs.input.focus()
        })
      },

      handleInputConfirm() {
        let inputValue = this.inputValue
        if (inputValue) {
          // this.tagList.push(inputValue)
          console.log(inputValue)
          editStyle({ type: this.type, name: inputValue }).then((res) => {
            this.getStyle()
            this.$message.success('创建成功')
          })
        }
        this.inputVisible = false
        this.inputValue = ''
      },

      // 获取类型
      getStyle() {
        styleList({ type: this.type }).then((res) => {
          this.tagList = res.data
        })
      },
      getStyleList(type = 3) {
        if (type == 3) {
          // 获取陈列类型下拉
          styleList({ type: 3 }).then((res) => {
            this.styleSelect = res.data
          })
        } else if (type == 4) {
          // 陈列商品单位下拉
          styleList({ type: 4 }).then((res) => {
            this.unitSelect = res.data
          })
        }
      },
      addRow() {
        this.model_goods.push({
          goods_name: '',
          goods_id: '',
          display_type: '',
          number: '',
          unit_id: '',
          remark: '',
        })
      },
      addRows(val, idx) {
        val.forEach((item) => {
          this.model_goods.splice(idx, 0, {
            goods_name: item.goods_name,
            goods_id: item.goods_id,
            display_type: '',
            number: '',
            unit_id: '',
            remark: '',
          })
        })
      },
      selectGoodsId(val, row) {
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
        if (this.model_goods[this.model_goods.length - 1].goods_id !== '') {
          this.addRow()
        }
      },
      handleCopyRow(index, row) {
        this.model_goods.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handleDeleteRow(index, row) {
        if (row.goods_id == '') {
          this.$message.error('不能删除此行')
          return false
        }
        if (this.model_goods.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.model_goods.splice(index, 1)
        }
      },
      // 陈列类型编辑
      editStyle() {
        this.title = '编辑陈列类型'
        this.type = 3
        this.showDialog = true
      },
      // 编辑单位下拉
      editUnit() {
        this.title = '编辑单位'
        this.type = 4
        this.showDialog = true
      },
      clearForm() {
        this.model_goods = [
          {
            goods_name: '',
            goods_id: '',
            display_type: '',
            number: '',
            unit_id: '',
            remark: '',
          },
        ]
      },
      save() {
        let data = []
        this.model_goods.forEach((item) => {
          if (item.goods_id !== '') {
            data.push(item)
          }
        })
        console.log('table1:', data)
        return JSON.stringify(data)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-icon-edit-outline {
    cursor: pointer;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 10px;
    margin-left: 10px;
    line-height: 30px;
  }
  .input-new-tag {
    width: 90px;
    margin-top: 10px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .el-tag--small {
    height: 30px;
    line-height: 30px;
  }
</style>
