<template>
  <!-- NAME[epic=动销] 创建付费陈列 -->
  <div class="orderTest-container">
    <el-form :model="form" inline>
      <el-form-item label="">
        <el-input v-model="form.keyword" placeholder="活动名称"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select @change-brand="bradnChange"></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          v-model="form.goods_name"
          placeholder="陈列商品名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="handleAdd">创建新活动</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '指定终端'">
            <span v-if="row.count_cust > 0">是</span>
            <span v-else>否</span>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleEditRow($index, row)"
          >
            修改
          </el-button>

          <el-button
            v-if="row.status == 0"
            type="text"
            @click.native.prevent="handleStop($index, row)"
          >
            停用
          </el-button>
          <el-button
            v-else
            type="text"
            @click.native.prevent="handleOpen($index, row)"
          >
            启用
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleCopy($index, row)"
          >
            复制
          </el-button>
          <!--          <el-button-->
          <!--            type="text"-->
          <!--            @click.native.prevent="handlePrint($index, row)"-->
          <!--          >-->
          <!--            打印-->
          <!--          </el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <add ref="add" @refresh="fetchData"></add>
    <check ref="check" @refresh="fetchData"></check>
    <!-- <edit ref="edit" @refresh="fetchData"></edit> -->
  </div>
</template>
<script>
  import _ from 'lodash'
  import BrandSelect from '@/baseComponents/brandSelect'
  import { getList, toggleStatus } from '@/api/payList'
  import Add from './components/add'
  // import Edit from './components/edit'
  import Check from './components/check'
  export default {
    name: 'CreatePayList',
    components: { Add, Check, BrandSelect },
    data() {
      return {
        loading: false,
        statusList: [
          {
            id: 1,
            name: '停用',
          },
          {
            id: 0,
            name: '启用',
          },
        ],
        list: [],
        time: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          goods_name: '',
          brand_id: '',
          pageNo: 1,
          pageSize: 10,
          sort: '', //
          order: '', //
          keyword: '', //名称
          start_time: '', //开始时间
          end_time: '', //结束时间
          status: '', //状态 1不启用 0启用
        },
        checkList: [
          '活动ID',
          '活动名称',
          '报名截止时间',
          '陈列开始时间',
          '陈列天数',
          '兑付期数',
          '指定终端',
          '参加终端数量',
          '状态',
        ],
        columns: [
          {
            order: 1,
            label: '活动ID',
            prop: 'code',
            width: '',
          },
          {
            order: 2,
            label: '活动名称',
            prop: 'name',
            width: '',
          },
          {
            order: 3,
            label: '报名截止时间',
            prop: 'end_time',
            width: '110px',
          },
          {
            order: 4,
            label: '陈列开始时间',
            prop: 'add_start_time',
            width: '110px',
          },
          {
            order: 5,
            label: '陈列天数',
            prop: 'show_day',
            width: '80px',
          },
          {
            order: 6,
            label: '兑付期数',
            prop: 'cash_phase_num',
            width: '80px',
          },
          {
            order: 7,
            label: '指定终端',
            prop: 'count_cust',
            width: '80px',
          },
          {
            order: 8,
            label: '参加终端数量',
            prop: 'ok_cust_num',
            width: '110px',
          },
          {
            order: 9,
            label: '状态',
            prop: 'status_text',
            width: '90',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getList(this.form)
        this.total = totalCount
        this.list = data
        this.loading = false
      },
      handleAdd() {
        this.$refs.add.showDialog = true
      },
      handleCheck(index, row) {
        this.$refs.check.id = row.id
        this.$refs.check.title = '查看陈列活动'
        this.$refs.check.isEdit = false
        this.$refs.check.showDialog = true
        this.$refs.check.checkbox = true
      },
      handleCopy(idx, row) {
        this.$refs.check.title = '复制陈列活动'

        this.$refs.check.showDialog = true
        this.$refs.check.isEdit = true
        this.$refs.check.copy = true

        this.$refs.check.id = row.id
      },
      handleEditRow(index, row) {
        this.$refs.check.title = '修改陈列活动'
        this.$refs.check.checkbox = false
        this.$refs.check.showDialog = true
        this.$refs.check.isEdit = true
        this.$refs.check.copy = false

        this.$refs.check.id = row.id
      },
      handleStop(index, row) {
        toggleStatus({ id: row.id, field: 'status', value: 1 }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
      handleOpen(index, row) {
        toggleStatus({ id: row.id, field: 'status', value: 0 }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
      handlePrint(index, row) {},
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      bradnChange(v) {
        this.form.brand_id = v
      },
    },
  }
</script>
<style lang="scss" scoped></style>
