var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "批量添加客户",
        visible: _vm.showDialog,
        top: "5vh",
        width: "1100px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-form",
            { ref: "elform", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                [
                  _c("sale-Area-select-tree", {
                    ref: "saleAreaSelectTree",
                    on: { "set-sale-select-id": _vm.setSaleSelectId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "channel_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "请选择渠道" },
                      model: {
                        value: _vm.form.channel_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channel_id", $$v)
                        },
                        expression: "form.channel_id",
                      },
                    },
                    _vm._l(_vm.channelList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "staff_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: "请选择业务员" },
                      model: {
                        value: _vm.form.staff_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "staff_id", $$v)
                        },
                        expression: "form.staff_id",
                      },
                    },
                    _vm._l(_vm.staffList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("client-search", {
                    ref: "clientSearch",
                    attrs: { "popper-class": "selectDC" },
                    on: { "select-val": _vm.setClientKeyword },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "form",
              attrs: {
                stripe: "",
                data: _vm.list,
                "row-key": _vm.getRowKey,
                height: "320px",
              },
              on: { "selection-change": _vm.setSelectRow },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _vm._l(_vm.finalluColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    width: "",
                    label: item.label,
                    prop: item.prop,
                    align: "center",
                  },
                })
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              layout: _vm.layout,
              "page-size": _vm.form.pageSize,
              total: _vm.totalCount,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
            [_vm._v("添加")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }