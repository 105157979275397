var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.model_goods },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "goods_name",
              label: "陈列商品",
              width: "220px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("goods-search", {
                      ref: "goodsSearch",
                      attrs: {
                        "f-key": row.goods_name,
                        disab: !_vm.isEdit,
                        search: _vm.isEdit,
                      },
                      on: {
                        "select-goods-all": function ($event) {
                          return _vm.selectGoodsId1($event, row)
                        },
                        "add-rows": function ($event) {
                          return _vm.addRows1($event, $index)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "display_type",
                label: "陈列类型",
                width: "150px",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            clearable: "",
                            placeholder: "陈列类型",
                            disabled: !_vm.isEdit,
                          },
                          model: {
                            value: row.display_type,
                            callback: function ($$v) {
                              _vm.$set(row, "display_type", $$v)
                            },
                            expression: "row.display_type",
                          },
                        },
                        _vm._l(_vm.styleSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.isEdit
                ? _c("template", { slot: "header" }, [
                    _vm._v(" 陈列类型 "),
                    _c("i", {
                      staticClass: "el-icon-edit-outline",
                      on: { click: _vm.editStyle },
                    }),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              prop: "number",
              label: "数量",
              width: "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      attrs: {
                        type: "number",
                        min: 0,
                        placeholder: "数量",
                        disabled: !_vm.isEdit,
                      },
                      model: {
                        value: row.number,
                        callback: function ($$v) {
                          _vm.$set(row, "number", $$v)
                        },
                        expression: "row.number",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "unit_id",
                label: "单位",
                width: "110px",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            clearable: "",
                            placeholder: "单位",
                            disabled: !_vm.isEdit,
                          },
                          model: {
                            value: row.unit_id,
                            callback: function ($$v) {
                              _vm.$set(row, "unit_id", $$v)
                            },
                            expression: "row.unit_id",
                          },
                        },
                        _vm._l(_vm.unitSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.isEdit
                ? _c("template", { slot: "header" }, [
                    _vm._v(" 单位 "),
                    _c("i", {
                      staticClass: "el-icon-edit-outline",
                      on: { click: _vm.editUnit },
                    }),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              prop: "remark",
              label: "备注",
              width: "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "备注", disabled: !_vm.isEdit },
                      model: {
                        value: row.remark,
                        callback: function ($$v) {
                          _vm.$set(row, "remark", $$v)
                        },
                        expression: "row.remark",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm.isEdit
            ? _c("el-table-column", {
                attrs: { prop: "", label: "操作", width: "", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var $index = ref.$index
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleCopyRow1($index, row)
                                },
                              },
                            },
                            [_vm._v(" 复制 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleDeleteRow($index, row)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  631256182
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.title1,
            visible: _vm.showDialogTitle1,
            width: "600px",
            top: "15vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialogTitle1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "orderTest-container" },
            [
              _vm._l(_vm.tagList, function (tag, idx) {
                return _c(
                  "el-tag",
                  {
                    key: idx,
                    attrs: { closable: "", "disable-transitions": false },
                    on: {
                      close: function ($event) {
                        return _vm.handleClose1(tag)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(tag.name) + " ")]
                )
              }),
              _vm.inputVisible
                ? _c("el-input", {
                    ref: "saveTagInput",
                    staticClass: "input-new-tag",
                    attrs: { size: "small" },
                    on: { blur: _vm.handleInputConfirm1 },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleInputConfirm1.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inputValue,
                      callback: function ($$v) {
                        _vm.inputValue = $$v
                      },
                      expression: "inputValue",
                    },
                  })
                : _c(
                    "el-button",
                    {
                      staticClass: "button-new-tag",
                      attrs: { size: "small" },
                      on: { click: _vm.showInput1 },
                    },
                    [_vm._v(" 新建 ")]
                  ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }