<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-width="110px"
        label-position="right"
      >
        <el-form-item label="活动名称:" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入活动名称"
            style="width: 350px"
            :disabled="!isEdit"
          ></el-input>
        </el-form-item>
        <el-form-item label="报名时间:" prop="">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled="!isEdit"
          ></el-date-picker>
        </el-form-item>
        <div class="df">
          <el-form-item label="陈列开始日期" prop="add_start_time">
            <el-date-picker
              v-model="form.add_start_time"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              style="width: 175px"
              :disabled="!isEdit"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="陈列天数:" prop="show_day">
            <el-input
              v-model="form.show_day"
              style="width: 65px"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </div>
        <div class="df">
          <el-form-item label="限定业务员:" prop="">
            <span style="margin-right: 25px">
              {{ form.as_user_ids.length }}
            </span>
            <el-button v-if="isEdit" type="primary" @click="handleSetStaff">
              设置
            </el-button>
          </el-form-item>
          <el-form-item prop="join_more">
            <el-checkbox
              v-model="form.join_more"
              :true-label="1"
              :false-label="0"
              :disabled="checkbox"
            >
              活动可重复参加
            </el-checkbox>
          </el-form-item>
        </div>
        <el-form-item label="限定门店家数:" prop="cust_limit">
          <el-input v-model="form.cust_limit" style="width: 80px" />
          家
          <span style="color: red; margin-left: 10px">
            不填写数量时无限制，填入数量达到家数后不能再邀请新户参加此活动
          </span>
        </el-form-item>
        <el-form-item label="活动说明:" prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            style="width: 350px"
            :disabled="!isEdit"
          ></el-input>
        </el-form-item>
      </el-form>
      <set-component
        ref="setComponent"
        :is-create="true"
        :chenlie="true"
        @set-userid="setUserId"
      ></set-component>
      <table1
        v-if="show"
        ref="table1"
        style="margin-bottom: 15px"
        :data="form.model_goods"
        :is-edit="isEdit"
      ></table1>
      <!-- <table2
        v-if="show"
        ref="table2"
        :data="form.cash_phase"
        :is-edit="isEdit"
      ></table2> -->
      <div style="overflow: hidden">
        <div
          v-for="(item, index) in list2"
          :key="index"
          style="position: relative"
        >
          <div v-if="isEdit" class="icons">
            <i class="el-icon-document-copy" @click="copyPlan2(index)">复制</i>
            <i class="el-icon-close" @click="deletePlan2(index)">删除</i>
          </div>
          <el-card shadow="hover">
            <div ref="tt">第 {{ index + 1 }} 期</div>
            <el-table stripe :data="item.goods_info" style="margin-top: 15px">
              <el-table-column
                width="220px"
                prop="goods_name"
                label="兑付商品"
                align="center"
              >
                <template #default="{ row }">
                  <goods-search
                    ref="goodsSearch"
                    :f-key="row.goods_name"
                    :search="isEdit"
                    :disab="!isEdit"
                    is-old
                    @select-goods-all="selectGoods2($event, row, index)"
                    @add-rows="addRows2($event, index)"
                  ></goods-search>
                </template>
              </el-table-column>
              <el-table-column
                prop="unit_id"
                label="单位"
                width="110px"
                align="center"
              >
                <template #default="{ row }">
                  <el-select
                    v-model="row.unit_id"
                    style="width: 100px"
                    placeholder="单位"
                    :disabled="!isEdit"
                    @change="unitChange2($event, row)"
                  >
                    <el-option
                      v-for="(i, idx) in row.arr_unit"
                      :key="idx"
                      :value="i.id"
                      :label="i.unit_name"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="goods_price" label="价格" align="center">
                <template #default="{ row }">
                  {{ row.goods_price || 0 }}
                </template>
              </el-table-column>
              <el-table-column prop="number" label="数量" align="center">
                <template #default="{ row }">
                  <el-input
                    v-model="row.number"
                    type="number"
                    placeholder="数量"
                    :min="0"
                    :disabled="!isEdit"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="" label="金额" align="center">
                <template #default="{ row }">
                  {{ Number(Number(row.number) * row.goods_price).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注" align="center">
                <template #default="{ row }">
                  <el-input
                    v-model="row.remark"
                    placeholder="备注"
                    :disabled="!isEdit"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                v-if="isEdit"
                prop=""
                label="操作"
                align="center"
              >
                <template #default="{ $index, row }">
                  <el-button
                    type="text"
                    @click.native.prevent="handleCopyRow2($index, row, index)"
                  >
                    复制
                  </el-button>
                  <el-button
                    type="text"
                    @click.native.prevent="handleDeleteRow2($index, row, index)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin: 10px 0;
              "
            >
              <div>
                兑付现金:
                <el-input
                  v-model.number="item.total_amount"
                  style="width: 200px"
                  :disabled="!isEdit"
                ></el-input>
              </div>
              <div>
                备注:
                <el-input
                  v-model="item.remark"
                  style="width: 200px"
                  :disabled="!isEdit"
                ></el-input>
              </div>
            </div>
            <div>
              兑付日期:
              <el-date-picker
                v-model="item.time_text"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="width: 200px"
                :disabled="!isEdit"
              ></el-date-picker>
            </div>
          </el-card>
        </div>
        <div
          style="display: flex; justify-content: space-between; margin: 10px 0"
        >
          <!-- TODO 一键多期兑付功能 -->
          <el-button v-if="isEdit" type="warning" @click="handleBatchAdd">
            一键复制多期兑付
          </el-button>
          <el-button v-if="isEdit" type="primary" @click="add2">
            添加新兑付
          </el-button>
        </div>
      </div>
      <client
        v-if="show"
        ref="client"
        :nums="form.as_cust_ids"
        :idd="form.id"
        :is-edit="isEdit"
        @set-client="setClient"
      ></client>
      <phone
        v-if="show"
        ref="phone"
        :data="form.cash_photo"
        :is-edit="isEdit"
      ></phone>
      <div style="margin-top: 15px; margin-left: 130px">
        <el-radio v-model="form.status" :label="0" :disabled="!isEdit">
          启用
        </el-radio>
        <el-radio v-model="form.status" :label="1" :disabled="!isEdit">
          停用
        </el-radio>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="!copy && isEdit" type="primary" @click="handleEdit">
        修改
      </el-button>
      <el-button v-if="copy && isEdit" type="primary" @click="handleCopy">
        复制
      </el-button>
      <el-button @click="close">关闭</el-button>
    </div>
    <batch-add ref="batchAdd" @batch-add="batchAdd"></batch-add>
  </el-dialog>
</template>
<script>
  import { addPayList, getPayListDetail, editPayList } from '@/api/payList'

  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import Table1 from './table1'
  import Client from './client'
  import Phone from './phone'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import BatchAdd from './batchAdd.vue'
  import { formatTimeStamp } from '@/utils/Time'
  export default {
    name: 'Check',
    components: { SetComponent, Table1, Client, Phone, GoodsSearch, BatchAdd },
    data() {
      return {
        copy: false,
        id: '',
        title: '',
        show: false,
        isEdit: false,
        showDialog: false,
        loading: false,
        checkbox: false,
        time: [],
        model_goods: [], //陈列商品
        cash_phase: [], //兑付期及商品
        cash_photo: [], //拍照照片
        form: {
          name: '', //名称
          start_time: '', //报名开始时间
          end_time: '', //报名结束时间
          add_start_time: '', //陈列开始时间
          show_day: '', //陈列天数
          content: '', //活动说明
          status: 0, //状态 1不启用 0启用
          as_user_ids: [], //限定业务员ID,多个逗号分隔
          as_cust_ids: [], //限定终端客户ID,多个逗号分隔
          model_goods: [], //陈列商品
          cash_phase: [], //兑付期及商品
          cash_photo: [], //拍照照片
          join_more: 1, //活动可重复参加
        },
        //list 2
        list2: [
          {
            title: '',
            time: '',
            total_amount: '',
            remark: '',
            goods_info: [
              {
                arr_unit: [],
                goods_name: '',
                goods_id: '',
                goods_price: '0',
                unit_id: '',
                number: '0',
                remark: '',
              },
            ],
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      showDialog(val) {
        if (!val) {
          this.clearForm()
        } else {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        console.log(this.id)
        let { data, msg, code } = await getPayListDetail({ id: this.id })
        console.log(data)
        this.form = data
        let time = [this.form.start_time, this.form.end_time]
        this.time = time
        this.form.as_user_ids = data.as_user_ids
        this.form.as_cust_ids = data.as_cust_ids
        this.form.cash_phase.forEach((item) => {
          item.goods_info.forEach((item2) => {
            item2.goods_price = item2.arr_unit.filter(
              (f) => f.id == item2.unit_id
            )[0].goods_price
          })
        })
        this.list2 = this.form.cash_phase
        if (this.list2.length == 0) {
          this.list2 = [
            {
              title: '',
              time: '',
              total_amount: '',
              remark: '',
              goods_info: [
                {
                  arr_unit: [],
                  goods_name: '',
                  goods_id: '',
                  goods_price: '0',
                  unit_id: '',
                  number: '0',
                  remark: '',
                },
              ],
            },
          ]
        } else {
          this.list2[0].goods_info.push({
            arr_unit: [],
            goods_name: '',
            goods_id: '',
            goods_price: '0',
            unit_id: '',
            number: '0',
            remark: '',
          })
        }
        this.show = true
        this.loading = false
      },
      // 设置限定业务员
      handleSetStaff() {
        if (this.form.as_user_ids.length != 0) {
          this.$refs.setComponent.getUserList(this.form.as_user_ids)
        }
        this.$refs.setComponent.showDialog = true
      },
      setClient(val) {
        console.log(val, '修改后')
        this.form.as_cust_ids = val
      },
      setUserId(val) {
        console.log(val)
        if (val == '') {
          this.form.as_user_ids = []
        } else {
          this.form.as_user_ids = val.split(',')
        }
      },
      handleEdit() {
        let data = JSON.parse(JSON.stringify(this.form))
        Object.assign(data, {
          as_user_ids: this.form.as_user_ids.join(','),
          model_goods: this.$refs.table1.save(),
          cash_phase: this.save2(),
          cash_photo: this.$refs.phone.save(),
        })
        console.log(data)

        data.as_cust_ids = data.as_cust_ids.join(',')

        editPayList(data).then((res) => {
          this.$message.success('修改成功')
          this.close()
          this.$emit('refresh')
        })
      },
      save() {
        let data = JSON.parse(JSON.stringify(this.form))
        Object.assign(data, {
          as_user_ids: this.form.as_user_ids.join(','),
          model_goods: this.$refs.table1.save(),
          cash_phase: this.save2(),
          cash_photo: this.$refs.phone.save(),
        })
        console.log(data)
        addPayList(data).then((res) => {
          this.$message.success('添加成功')
          this.close()
          this.$emit('refresh')
        })
      },
      close() {
        this.showDialog = false
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.time = []
        this.form.as_user_ids = []
        this.$refs.table1.clearForm()
        this.list2 = [
          {
            title: '',
            time: '',
            total_amount: '',
            remark: '',
            goods_info: [
              {
                arr_unit: [],
                goods_name: '',
                goods_id: '',
                goods_price: '0',
                unit_id: '',
                number: '0',
                remark: '',
              },
            ],
          },
        ]
        this.$refs.phone.clearForm()
      },
      // ? 一键兑付多期
      handleBatchAdd() {
        if (
          !this.list2[0].time_text ||
          ((this.list2[0].goods_info[0].goods_id == '' ||
            this.list2[0].goods_info[0].goods_id == -1 ||
            this.list2[0].goods_info[0].goods_id == undefined) &&
            !this.list2[0].total_amount)
        ) {
          this.$message.error('请输入兑付内容及兑付日期')
        } else {
          this.$refs.batchAdd.form.time = this.list2[0].time_text

          this.$refs.batchAdd.showDialog = true
        }

        // else {
        //   this.$refs.batchAdd.showDialog = true
        // }
      },
      batchAdd(val) {
        console.log('batch-add', val)
        // 时间戳
        let timeStamp = +new Date(val.time)
        // 一天的时间戳
        let dayStamp = 24 * 60 * 60 * 1000
        for (let i = 0; i < val.qi; i++) {
          if (val.checked == 1) {
            this.list2.push({
              title: '',
              time: '',
              time_text: formatTimeStamp(
                timeStamp + (i + 1) * (val.day1 * dayStamp)
              ),
              total_amount: JSON.parse(
                JSON.stringify(this.list2[0].total_amount)
              ),
              remark: JSON.parse(JSON.stringify(this.list2[0].remark)),
              goods_info: JSON.parse(JSON.stringify(this.list2[0].goods_info)),
            })
          } else if (val.checked == 2) {
            let nM = new Date(val.time).getMonth() + 1
            let month = new Date(timeStamp).setMonth(nM + i)
            let day = new Date(month).setDate(val.day2)
            let c = nM + i + 1 > 12 ? nM + i + 1 - 12 : nM + i + 1
            if (new Date(day).getMonth() + 1 != c) {
              console.log('这个月不对劲')
              // 不对劲的月份
              let m = new Date(day).getMonth() + 1
              let y = new Date(day).getFullYear()
              console.log('不对劲的月份', m - 1, y)
              day = new Date(y, m - 1 == 0 ? 1 : m - 1, 0)
              // let a = new Date(new Date(day).setMonth(m - 2))
              // let endDay = new Date(y, m - 1, 0).getDate()
              // console.log('endDay', endDay)
              // console.log('month', a.getMonth() + 1)
              // day = new Date(a).setDate(endDay)
              // console.log('day', new Date(day).getMonth() + 1)
            }
            this.list2.push({
              title: '',
              time: '',
              time_text: formatTimeStamp(day),
              total_amount: JSON.parse(
                JSON.stringify(this.list2[0].total_amount)
              ),
              remark: JSON.parse(JSON.stringify(this.list2[0].remark)),
              goods_info: JSON.parse(JSON.stringify(this.list2[0].goods_info)),
            })
          } else {
            this.$message.error('输入错误，请重新兑付')
          }
        }
      },
      /**
       * @table2的fun
       */
      selectGoods2(val, row, index) {
        console.log(row)
        row.goods_name = val.goods_name
        row.goods_id = val.goods_id
        row.arr_unit = val.arr_unit
        row.unit_id = val.unit_id
        row.goods_price = val.goods_price
        row.remark = val.info
        row.number = val.quantity
        if (
          this.list2[index].goods_info[this.list2[index].goods_info.length - 1]
            .goods_id !== ''
        ) {
          this.addRow2(index)
        }
      },
      addRow2(index) {
        this.list2[index].goods_info.push({
          goods_name: '',
          goods_id: '',
          unit_id: '',
          number: '0',
          remark: '',
          goods_price: '0',
          arr_unit: [],
        })
      },

      addRows2(val, index) {
        val.forEach((item) => {
          console.log('item', item)
          this.list2[index].goods_info.push({
            goods_name: item.goods_name,
            goods_id: item.goods_id,
            number: item.quantity,
            goods_price: item.goods_price,
            unit_id: item.unit_id,
            remark: item.info,
            arr_unit: item.arr_unit,
          })
        })
        this.list2[index].goods_info.forEach((i, idx) => {
          if (i.goods_id == '' || i.goods_id == -1) {
            this.list2[index].goods_info.splice(idx, 1)
          }
        })
        this.addRow2(index)
      },
      // 表格操作
      handleCopyRow2(index, row, idx) {
        this.list2[idx].goods_info.splice(
          index,
          0,
          JSON.parse(JSON.stringify(row))
        )
      },
      handleDeleteRow2(index, row, idx) {
        if (this.list2[idx].goods_info.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list2[idx].goods_info.splice(index, 1)
        }
      },
      handleCopy() {
        let data = JSON.parse(JSON.stringify(this.form))
        Object.assign(data, {
          as_user_ids: this.form.as_user_ids.join(','),
          model_goods: this.$refs.table1.save(),
          cash_phase: this.save2(),
          cash_photo: this.$refs.phone.save(),
        })
        console.log(data)
        if (data.as_cust_ids.length == 0) {
          data.as_cust_ids = ''
        }
        addPayList(data).then((res) => {
          this.$message.success('复制成功')
          this.close()
          this.$emit('refresh')
        })
      },
      add2() {
        this.list2.push({
          title: '',
          time: '',
          total_amount: '',
          remark: '',
          goods_info: [
            {
              arr_unit: [],
              goods_name: '',
              goods_id: '',
              goods_price: '',
              unit_id: '',
              number: '',
              remark: '',
            },
          ],
        })
      },
      copyPlan2(index) {
        this.list2.splice(
          index,
          0,
          JSON.parse(JSON.stringify(this.list2[index]))
        )
      },
      deletePlan2(index) {
        if (this.list2.length == 1) {
          this.$message.error('不能删除最后一项')
        } else {
          this.list2.splice(index, 1)
        }
      },
      unitChange2(val, row) {
        console.log(val)
        row.goods_price = row.arr_unit.filter(
          (item) => item.id == val
        )[0].old_sell_price
      },
      clearForm2() {
        this.list2 = {
          title: '',
          time: '',
          total_amount: '',
          remark: '',
          goods_info: [
            {
              arr_unit: [],
              goods_name: '',
              goods_id: '',
              goods_price: '0',
              unit_id: '',
              number: '0',
              remark: '',
            },
          ],
        }
      },
      save2() {
        let data = JSON.parse(JSON.stringify(this.list2))
        data.forEach((item, idx) => {
          item.time = item.time_text
          item.goods_info.forEach((item2, index2) => {
            if (item2.goods_id == '') {
              item.goods_info.splice(index2, 1)
            }
          })
          item.title = this.$refs.tt[idx].innerHTML
        })
        console.log('table2:', data)
        return JSON.stringify(data)
      },
      /**
       * @table2End
       */
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .icons {
    position: absolute;
    top: 10px;
    right: 10px;
    i {
      margin-right: 5px;
      cursor: pointer;
    }
  }
</style>
