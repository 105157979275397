<template>
  <el-dialog
    :modal="false"
    title="编辑拍照类型"
    :visible.sync="showDialog"
    width="600px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-tag
        v-for="(tag, idx) in tagList"
        :key="idx"
        closable
        :disable-transitions="false"
        @close="handleClose(tag)"
      >
        {{ tag.name }}
      </el-tag>
      <el-input
        v-if="inputVisible"
        ref="saveTagInput"
        v-model="inputValue"
        class="input-new-tag"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      ></el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput">
        新建
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'PhoneTypeEdit',
    components: {},
    data() {
      return {
        showDialog: false,
        tagList: [],
        inputValue: '',
        inputVisible: false,
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (v) {
          this.initSelect()
        } else {
          this.$emit('refresh')
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      initSelect() {
        postAction('/baseAdmin/photo-type/photo-type-list', {
          type: 2,
        }).then((r) => {
          this.tagList = r.data
        })
      },
      handleInputConfirm() {
        let inputValue = this.inputValue
        if (inputValue) {
          // this.tagList.push(inputValue)
          console.log(inputValue)
          postAction('/baseAdmin/photo-type/photo-type-add', {
            type: 2,
            name: inputValue,
          }).then((res) => {
            this.initSelect()
            this.$message.success('创建成功')
          })
        }
        this.inputVisible = false
        this.inputValue = ''
      },
      handleClose(tag) {
        console.log(tag)
        postAction('/baseAdmin/photo-type/photo-type-del', { id: tag.id }).then(
          (res) => {
            this.initSelect()
            this.$message.success('删除成功')
          }
        )
      },
      showInput() {
        this.inputVisible = true
        this.$nextTick((_) => {
          this.$refs.saveTagInput.$refs.input.focus()
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-icon-edit-outline {
    cursor: pointer;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 10px;
    margin-left: 10px;
    line-height: 30px;
  }
  .input-new-tag {
    width: 90px;
    margin-top: 10px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .el-tag--small {
    height: 30px;
    line-height: 30px;
  }
</style>
