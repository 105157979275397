<template>
  <el-dialog
    :modal="false"
    title="创建陈列活动"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-width="110px"
        label-position="right"
      >
        <el-form-item label="活动名称:" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入活动名称"
            style="width: 350px"
          ></el-input>
        </el-form-item>
        <el-form-item label="报名时间:" prop="">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <div class="df">
          <el-form-item label="陈列开始日期" prop="add_start_time">
            <el-date-picker
              v-model="form.add_start_time"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              style="width: 175px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="陈列天数:" prop="show_day">
            <el-input v-model="form.show_day" style="width: 65px"></el-input>
          </el-form-item>
        </div>
        <div class="df">
          <el-form-item label="限定业务员:" prop="">
            <span style="margin-right: 25px">
              {{ form.as_user_ids.length }}
            </span>
            <el-button type="primary" @click="handleSetStaff">设置</el-button>
          </el-form-item>
          <el-form-item prop="join_more">
            <el-checkbox
              v-model="form.join_more"
              :true-label="1"
              :false-label="0"
            >
              活动可重复参加
            </el-checkbox>
          </el-form-item>
        </div>
        <el-form-item label="限定门店家数:" prop="cust_limit">
          <el-input v-model="form.cust_limit" style="width: 80px" />
          家
          <span style="color: red; margin-left: 10px">
            不填写数量时无限制，填入数量达到家数后不能再邀请新户参加此活动
          </span>
        </el-form-item>
        <el-form-item label="活动说明:" prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            style="width: 350px"
          ></el-input>
        </el-form-item>
      </el-form>
      <set-component
        ref="setComponent"
        :is-create="true"
        @r-table-data="getRtable"
        @set-userid="setUserId"
      ></set-component>
      <table1 ref="table1" style="margin-bottom: 15px"></table1>
      <table2 ref="table2"></table2>
      <client ref="client" @set-client="setClient"></client>
      <phone ref="phone"></phone>
      <div style="margin-top: 15px; margin-left: 130px">
        <el-radio v-model="form.status" label="0">启用</el-radio>
        <el-radio v-model="form.status" label="1">停用</el-radio>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { addPayList } from '@/api/payList'
  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import Table1 from './table1'
  import Table2 from './table2'
  import Client from './client'
  import Phone from './phone'
  export default {
    name: 'Add',
    components: { SetComponent, Table1, Table2, Client, Phone },
    data() {
      return {
        showDialog: false,
        time: [],
        model_goods: [], //陈列商品
        cash_phase: [], //兑付期及商品
        cash_photo: [], //拍照照片
        form: {
          name: '', //名称
          start_time: '', //报名开始时间
          end_time: '', //报名结束时间
          add_start_time: '', //陈列开始时间
          cust_limit: '', //限定门店家数
          show_day: '', //陈列天数
          content: '', //活动说明
          status: '0', //状态 1不启用 0启用
          as_user_ids: [], //限定业务员ID,多个逗号分隔
          as_cust_ids: '', //限定终端客户ID,多个逗号分隔
          join_more: 0, //活动可重复参加
        },
        rTableData: [],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      showDialog(val) {
        if (!val) {
          this.clearForm()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 设置限定业务员
      handleSetStaff() {
        this.$refs.setComponent.rTableData = JSON.parse(
          JSON.stringify(this.rTableData)
        )
        this.$refs.setComponent.showDialog = true
      },
      setClient(val) {
        this.form.as_cust_ids = val
      },
      setUserId(val) {
        console.log(val)
        if (val == '') {
          this.form.as_user_ids = []
        } else {
          this.form.as_user_ids = val.split(',')
        }
      },
      save() {
        let data = JSON.parse(JSON.stringify(this.form))
        Object.assign(data, {
          as_user_ids: this.form.as_user_ids.join(','),
          model_goods: this.$refs.table1.save(),
          cash_phase: this.$refs.table2.save(),
          cash_photo: this.$refs.phone.save(),
        })
        console.log(data)
        addPayList(data).then((res) => {
          this.$message.success('添加成功')
          this.close()
          this.$emit('refresh')
        })
      },
      close() {
        this.showDialog = false
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.time = []
        this.form.as_user_ids = []
        this.$refs.table1.clearForm()
        this.$refs.table2.clearForm()
        this.$refs.phone.clearForm()
        this.rTableData = []
        this.$refs.setComponent.rTableData = []
      },
      getRtable(v) {
        this.rTableData = v
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
</style>
