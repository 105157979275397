<template>
  <div>
    <div class="df">
      <div class="cen" style="padding-right: 15px; text-align: right">
        <!-- <el-checkbox
          v-model="checked"
          :disabled="!isEdit"
          style="margin-right: 15px"
        >
          指定陈列终端
        </el-checkbox> -->
        指定陈列终端
      </div>
      <div>
        <el-table stripe :data="list" border>
          <el-table-column
            align="center"
            prop="num"
            label="指定终端"
            width="150px"
          >
            <template>
              {{ nums.length }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="isEdit"
            align="center"
            prop=""
            label="操作"
            width="110px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleEdit($index, row)"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <link-client ref="linkClient" @set-client-num="setClientNum"></link-client>
  </div>
</template>
<script>
  import LinkClient from './linkClient'
  import { getClientList } from '@/api/payList'
  export default {
    name: 'Client',
    components: { LinkClient },
    props: {
      nums: {
        type: Array,
        default: () => [],
      },
      idd: {
        type: Number,
        default: () => 0,
      },
      isEdit: {
        type: Boolean,
        default: () => true,
      },
    },
    data() {
      return { checked: true, list: [{ num: 0 }], ids: '', tableData_: [] }
    },
    computed: {},
    watch: {
      nums(val) {
        if (val) {
          this.list = [{ num: this.nums.length }]
          // 获取 cust
          // getClientList({
          //   pageSize: 10,
          //   pageNo: 1,
          //   id: this.idd,
          // }).then((res) => {
          //   console.log(res)
          //   this.tableData_ = res.data
          // })
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      setClientNum(val) {
        console.log(val, '批量添加修改')
        // this.list = [{ num: val.length }]
        let ids = []
        val.forEach((item) => {
          ids.push(item.id)
        })
        this.$emit('set-client', ids)
        console.log(ids)
      },
      handleEdit(index, row) {
        this.$refs.linkClient.showDialog = true
        console.log(this.nums)
        this.$refs.linkClient.ids = this.nums
        this.$refs.linkClient.getClient()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    justify-content: flex-start;
  }
  .cen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 125px;
    line-height: 35px;
  }
</style>
