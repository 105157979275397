var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "创建陈列活动",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "110px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称:", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入活动名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "报名时间:", prop: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "df" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "陈列开始日期", prop: "add_start_time" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "175px" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.add_start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "add_start_time", $$v)
                          },
                          expression: "form.add_start_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "陈列天数:", prop: "show_day" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65px" },
                        model: {
                          value: _vm.form.show_day,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "show_day", $$v)
                          },
                          expression: "form.show_day",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "df" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "限定业务员:", prop: "" } },
                    [
                      _c("span", { staticStyle: { "margin-right": "25px" } }, [
                        _vm._v(" " + _vm._s(_vm.form.as_user_ids.length) + " "),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSetStaff },
                        },
                        [_vm._v("设置")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "join_more" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.form.join_more,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "join_more", $$v)
                            },
                            expression: "form.join_more",
                          },
                        },
                        [_vm._v(" 活动可重复参加 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "限定门店家数:", prop: "cust_limit" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80px" },
                    model: {
                      value: _vm.form.cust_limit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cust_limit", $$v)
                      },
                      expression: "form.cust_limit",
                    },
                  }),
                  _vm._v(" 家 "),
                  _c(
                    "span",
                    { staticStyle: { color: "red", "margin-left": "10px" } },
                    [
                      _vm._v(
                        " 不填写数量时无限制，填入数量达到家数后不能再邀请新户参加此活动 "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动说明:", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("set-component", {
            ref: "setComponent",
            attrs: { "is-create": true },
            on: { "r-table-data": _vm.getRtable, "set-userid": _vm.setUserId },
          }),
          _c("table1", {
            ref: "table1",
            staticStyle: { "margin-bottom": "15px" },
          }),
          _c("table2", { ref: "table2" }),
          _c("client", { ref: "client", on: { "set-client": _vm.setClient } }),
          _c("phone", { ref: "phone" }),
          _c(
            "div",
            { staticStyle: { "margin-top": "15px", "margin-left": "130px" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("启用")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [_vm._v("停用")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }