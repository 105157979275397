<template>
  <div>
    <div class="df">
      <div class="cen">
        <el-checkbox v-model="checked" style="margin-right: 15px">
          指定陈列终端
        </el-checkbox>
      </div>
      <div>
        <el-table stripe :data="list" border>
          <el-table-column
            align="center"
            prop="num"
            label="指定终端"
            width="150px"
          ></el-table-column>
          <el-table-column align="center" prop="" label="操作" width="110px">
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleEdit($index, row)"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <link-client ref="linkClient" @set-client-num="setClientNum"></link-client>
  </div>
</template>
<script>
  import LinkClient from './linkClient'

  export default {
    name: 'Client',
    components: { LinkClient },
    data() {
      return { checked: false, list: [{ num: 0 }], ids: '' }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      setClientNum(val) {
        this.list = [{ num: val.length }]
        let id = []
        val.forEach((item) => {
          id.push(item.id)
        })
        this.ids = id.join(',')
        this.$emit('set-client', this.ids)
      },
      handleEdit(index, row) {
        this.$refs.linkClient.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    justify-content: flex-start;
  }
  .cen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 125px;
    line-height: 35px;
  }
</style>
