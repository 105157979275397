<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="批量添加客户"
    :visible.sync="showDialog"
    top="5vh"
    width="1100px"
  >
    <div class="wrapper">
      <el-form ref="elform" :model="form" inline>
        <el-form-item>
          <sale-Area-select-tree
            ref="saleAreaSelectTree"
            @set-sale-select-id="setSaleSelectId"
          ></sale-Area-select-tree>
        </el-form-item>
        <el-form-item prop="channel_id">
          <el-select
            v-model="form.channel_id"
            clearable
            placeholder="请选择渠道"
            style="width: 160px"
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="staff_id">
          <el-select
            v-model="form.staff_id"
            clearable
            placeholder="请选择业务员"
            style="width: 160px"
          >
            <el-option
              v-for="item in staffList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <client-search
            ref="clientSearch"
            :popper-class="'selectDC'"
            @select-val="setClientKeyword"
          ></client-search>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="form"
        v-loading="loading"
        stripe
        :data="list"
        :row-key="getRowKey"
        height="320px"
        @selection-change="setSelectRow"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finalluColumns"
          :key="index"
          width=""
          :label="item.label"
          :prop="item.prop"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="totalCount"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleAdd">添加</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getList } from '@/api/chooseClientDialog'

  import {
    getClientLevelList,
    getClientChannelList,
    getStaffList,
    getLinkClientTableList,
    addLinkClient,
  } from '@/api/setPrice'
  export default {
    name: 'BatchAdd',
    components: { SaleAreaSelectTree, ClientSearch },
    props: {
      clientLevelList: { type: Array, default: () => [] },
      channelList: { type: Array, default: () => [] },
    },
    data() {
      return {
        id: '',
        loading: true,
        showDialog: false,
        selectRow: [], //已选择的row
        staffList: [], // 业务员下拉列表
        timeList: [], // 建店时间下拉
        totalCount: 0,
        layout: 'total, prev, pager, next',
        form: {
          pageNo: 1,
          pageSize: 10,
          area_id: '',
          channel_id: '',
          class_id: '', // 客户等级id
          staff_id: '', //业务员id
          keyword: '',
          time_id: '', // 建店时间
          start_time: '',
          end_time: '',
        },
        // 表格
        list: [],
        checkList: ['客户名称', '地址', '老板', '电话'],
        columns: [
          {
            order: 1,
            prop: 'cust_name',
            label: '客户名称',
            width: 'auto',
          },
          {
            order: 1,
            prop: 'address',
            label: '地址',
            width: 'auto',
          },
          {
            order: 1,
            prop: 'boss',
            label: '老板',
            width: 'auto',
          },
          {
            order: 1,
            prop: 'mobile',
            label: '电话',
            width: 'auto',
          },
        ],
      }
    },
    computed: {
      finalluColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.loading = true
          this.resetForm()
        }
      },
    },
    created() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
    },
    mounted() {},
    methods: {
      getRowKey(row) {
        return row.id
      },
      setSaleSelectId(val) {
        this.form.area_id = val
      },
      // 获取表格数据
      async fetchData() {
        this.loading = true

        let { data, code, msg, totalCount } = await getList(this.form)
        console.log(data)
        if (code == 200) {
          this.list = data
          this.totalCount = totalCount
          this.loading = false
        }
      },
      close() {
        this.showDialog = false
      },
      // 设置勾选的row
      setSelectRow(val) {
        this.selectRow = val
      },
      setClientKeyword(val) {
        this.form.keyword = val
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleAdd() {
        if (this.selectRow.length == 0) {
          this.$message.error('请勾选客户')
          return false
        }
        // addLinkClient
        if (this.selectRow.length !== 0) {
          console.log(this.selectRow)
          this.$emit('add-rows', this.selectRow)
          this.close()
        }
      },
      // 分页
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      resetForm() {
        console.log('resetFrom')
        this.$refs['elform'].resetFields()
        this.$refs['saleAreaSelectTree'].resetForm()
        this.$refs['clientSearch'].resetForm()
        this.form.keyword = ''
      },
    },
  }
</script>
<style lang="scss" scoped></style>
